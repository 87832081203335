/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import {
  Input,
  InputFormat,
  DefaultSelect,
  TrueFalseSelect,
  Widget,
  MalhaSelect,
  GerenciaSelect,
  PoloSelect,
  OptionSelect,
  DatePicker
} from '../../components';
import { masks } from '../../helpers';
import * as selectorsUsuarios from '../../store/usuario/reducer';
import * as actionsUsuarios from '../../store/usuario/actions';

const useStyles = makeStyles(() => ({
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  gridContainer: {
    padding: 24
  },
  classNameCard: {
    overflow: 'visible'
  }
}));

const InformacaoBasica = ({ id, values, setValues, errors, readOnly, isAdmin, proximaMatricula, findProximaMatricula }) => {
  const classes = useStyles();

  useEffect(() => {
    if (!id) {
      findProximaMatricula();
    }
  }, [findProximaMatricula]);

  useEffect(() => {
    setValues({
      ...values,
      matricula: proximaMatricula
    });
  }, [proximaMatricula]);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeRole = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
      subRole: null,
    });
  };

  const onChangePhone = event => {
    setValues({
      ...values,
      telefone: masks.removePhoneMask(event.target.value.substring(0, 15))
    })
  }

  const getRoleOptions = () => {
    const roles = [
      {id: 'ROLE_ADMIN', nome: 'Administrador'},
      {id: 'ROLE_CROQUI', nome: 'Croqui'},
      {id: 'ROLE_DIGITADOR', nome: 'Digitador'},
      {id: 'ROLE_INSPETOR', nome: 'Inspetor'}
    ];

    if (isAdmin) {
      roles.push({ id: 'ROLE_SUPER_ADMIN', nome: 'Super Administrador' });
    }

    return roles;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          disabled
          label="Matrícula"
          value={values.id ? values.matricula : proximaMatricula}
        />
      </Grid>
      <Grid item xs={6} className={classes.fieldContainer}>
        <Input
          error={errors && errors.nome}
          isRequired
          label="Nome Completo"
          name="nome"
          onChange={handleChange}
          value={values.nome}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          label="Apelido"
          name="apelido"
          onChange={handleChange}
          value={values.apelido}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={6} className={classes.fieldContainer}>
        <Input
          error={errors && errors.email}
          isRequired
          label="Login"
          name="email"
          onChange={handleChange}
          value={values.email}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={4} className={classes.fieldContainer}>
        <Input
          isRequired
          label="Email"
          name="mail"
          onChange={handleChange}
          value={values.mail}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={2} className={classes.fieldContainer}>
        <TrueFalseSelect
          label="Ativo"
          showEmpty={false}
          onChange={() => setValues({
            ...values,
            ativo: !values.ativo
          })}
          value={values.ativo}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <InputFormat
          label="Telefone"
          name="telefone"
          onChange={onChangePhone}
          value={values.telefone}
          format="(##) #####-####"
          disabled={readOnly}
          mask="_"
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <DefaultSelect
          error={errors && errors.role ? true : false}
          isRequired
          label="Perfil"
          name="role"
          showEmpty
          options={getRoleOptions()}
          onSelect={handleChangeRole}
          value={values.role}
          disabled={readOnly}
        />
      </Grid>
      {values.role === "ROLE_INSPETOR" && <Grid item xs={3} className={classes.fieldContainer}>
        <DefaultSelect
          error={errors && errors.subRole ? true : false}
          isRequired
          label="Sub-Perfil"
          name="subRole"
          showEmpty
          options={[
            {id: 'SUBROLE_OBSERVADOR', nome: 'Observador Operador de RPA'},
            {id: 'SUBROLE_INSPETOR', nome: 'Inspetor Operador de RPA'},
            {id: 'SUBROLE_OPERADOR_MAQUINA', nome: 'Operador de Máquina'},
          ]}
          onSelect={event => {
            setValues({
              ...values,
              [event.target.name]: event.target.value || null,
            });
          }}
          value={values.subRole}
          disabled={readOnly}
        />
      </Grid>}
      <Grid item xs={3} className={classes.fieldContainer}>
        <OptionSelect
          label="Setor"
          codigo="USUARIO_SETOR"
          onSelect={value => setValues({
            ...values,
            setorId: value
          })}
          value={values.setorId}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <TrueFalseSelect
          label="Possui Carteira Assinada"
          onChange={event => setValues({
            ...values,
            possuiCarteira: event.target.value || null
          })}
          value={values.possuiCarteira}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <DatePicker
          label="Data Admissão"
          onChange={data => setValues({
            ...values,
            dataAdmissao: data.valueOf()
          })}
          value={values.dataAdmissao}
          showInput
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <DatePicker
          label="Data Demissão "
          onChange={data => setValues({
            ...values,
            dataDemissao: data.valueOf()
          })}
          value={values.dataDemissao}
          showInput
        />
      </Grid>
      <Grid item xs={12} className={classes.fieldContainer}>
        <Widget title={"Local de Trabalho"} classNameCard={classes.classNameCard}>
          <Grid container spacing={3} className={classes.gridContainer}>
            <Grid item xs={12}>
              <MalhaSelect
                onSelect={value => setValues({ ...values, additions: {...values.additions, malhaIds: value} })}
                value={values.additions.malhaIds}
                multiSelect
              />
            </Grid>
            <Grid item xs={12}>
              <GerenciaSelect
                onSelect={value => setValues({ ...values, additions: {...values.additions, gerenciaIds: value} })}
                value={values.additions.gerenciaIds}
                multiSelect
                useFilter
                malhaId={values.additions.malhaIds}
              />
            </Grid>
            <Grid item xs={12}>
              <PoloSelect
                onSelect={value => setValues({ ...values, additions: {...values.additions, poloIds: value} })}
                value={values.additions.poloIds}
                isMultiple
                useFilter
                malhaId={values.additions.malhaIds}
                gerenciaId={values.additions.gerenciaIds}
              />
            </Grid>
          </Grid>
        </Widget>
      </Grid> 
    </Grid>
  );
};

const mapStateToProps = state => ({
  proximaMatricula: selectorsUsuarios.getProximaMatricula(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsUsuarios,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InformacaoBasica);
