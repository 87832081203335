import React from 'react';
import Input from '../Input';

const options = {
  'id': 'NOT_SYNC', nome: 'Não sincronizado',
  'id': 'SYNC_TO_APP', nome: 'Sincronizado para o app',
  'id': 'SYNC_FROM_APP', nome: 'Sincronizado do app',
}

const NsirSyncStatusSelect = ({ value }) => {
  return (
    <Input
      label="Status Sincronização"
      value={value ? options[value] : ""}
      disabled
    />
  );
}

export default NsirSyncStatusSelect;